import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['name', 'folders', 'formContainer', 'newFolderButton']

  onPostSuccess(event) {
    const [data, status, xhr] = event.detail
    this.foldersTarget.innerHTML += `<div class="col-md-3">${xhr.response}</div>`
    this.nameTarget.value = ''
    this.doToggle()
  }

  onToggle() {
    this.doToggle()
  }

  onCancel() {
    this.doToggle()
  }

  doToggle() {
    this.formContainerTarget.classList.toggle('hidden')
    this.newFolderButtonTarget.classList.toggle('hidden')
  }
}